import { Dialog } from '@rawbot/roger-dubuis-styleguide/dist/styleguide';

export default class AbstractDialog {
  type;
  id;
  options;
  constructor(type, element, options = {}) {
    if (this.constructor === AbstractDialog) {
      throw new Error("Abstract classes can't be instantiated.");
    }

    this.type = type;
    this.id = element.id;

    if (Object.hasOwn(options, 'onHide')) {
      options.onClose = options.onHide;
      delete options.onHide;
    } else if (Object.hasOwn(options, 'onHidden')) {
      options.onClose = options.onHidden;
      delete options.onHidden;
    }

    if (Object.hasOwn(options, 'onShown') && !Object.hasOwn(options, 'onShow')) {
      options.onShow = options.onShown;
      delete options.onShown;
    }

    options.closeTrigger = `data-${this.type}-close`;
    this.options = options;

    document.querySelectorAll(`[data-${this.type}-open="${this.id}"]`).forEach((el) => {
      el.addEventListener('click', () => this.openListener());
    });
  }

  openListener() {
    this.show();
  }

  closeListener() {
    this.hide();
  }

  destroy() {
    document.querySelectorAll(`[data-${this.type}-open="${this.id}"]`).forEach((el) => {
      el.removeEventListener('click', () => this.openListener());
    });

    document.querySelectorAll(`[data-${this.type}-close="${this.id}"]`).forEach((el) => {
      el.removeEventListener('click', () => this.closeListener());
    });
  }

  show() {
    Dialog.show(this.id, this.options);
  }

  hide() {
    Dialog.close(this.id);
  }
}
